import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { SnackbarProvider } from 'notistack';
import { jwtDecode } from "jwt-decode";


const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  //setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwtDecode(token);
  // Set user and isAuthenticated
  //store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  
  if (decoded.exp < currentTime) {

    console.log("LOGOUT");

    console.log(window.location.href);
    // Logout user
    //store.dispatch(logoutUser());

    if (window.location.href.includes("sign-in") === false && window.location.href.includes("eventos/qr") === false) {
      // Redirect to login
      window.location.href = window.location.origin +"/sign-in";
    }
  } 
}
else {
    if (window.location.href.includes("sign-in") === false && window.location.href.includes("eventos/qr") === false) {
      // Redirect to login
      window.location.href = window.location.origin +"/sign-in";
    }
  }



export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
      <SnackbarProvider hideIconVariant={false}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
